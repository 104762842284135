import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Inject,
  forwardRef,
} from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { first, map, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorService } from 'src/app/core/error.service';
import { SidenavDrawerService } from 'src/app/core/sidenav-drawer.service';
import {
  StationInformation,
  QuestionFieldType,
  ConnectedStationInfo,
  ContainerNameField,
  Question,
  PossibleAnswer,
  FlowLogicRule,
  DataLinkObject,
  TextWidgetInformation,
  CustomIdSettings,
  WidgetType,
  GridsterItemWidget,
  EditDataWidget,
  DashboardData,
  CustomField,
  StationBucketQuestion,
  QuestionFieldIcon,
  StationAvailableLibrary,
  StandardStringJSON,
  ConfigurableObject,
  TextWidgetAlignmentType,
  CanNavigate,
  StationComponentListType,
  OriginTypeBucket,
  Library,
  GenericWidget,
  StationOrGroup,
} from 'src/models';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { forkJoin, fromEvent, Observable, Subject } from 'rxjs';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import {
  GridsterConfig,
  GridsterItem,
  GridsterItemComponentInterface,
} from 'angular-gridster2';
import { StationService } from 'src/app/core/station.service';
import { PopupService } from 'src/app/core/popup.service';
import { UserService } from 'src/app/core/user.service';
import { ContainerService } from 'src/app/core/container.service';
import { RulesComponent } from 'src/app/station/rules/rules.component';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { v4 as uuidv4 } from 'uuid';
import {
  ComponentHelper,
  InputFrame,
  JsonValidator,
  LibraryHelper,
  MobileBrowserChecker,
  TermsGeneric,
  WidgetFiltersHelper,
} from 'src/helpers';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { StationLibraryModalComponent } from 'src/app/station/station-library-modal/station-library-modal.component';
import { StationBucketModalComponent } from 'src/app/shared/bucket/station-bucket-modal/station-bucket-modal.component';
import { LibraryTreeComponent } from 'src/app/station/station-components-column/trees/library-tree/library-tree.component';
import { ComponentTreeComponent } from 'src/app/station/station-components-column/trees/component-tree/component-tree.component';
import { StationGridComponent } from 'src/app/station/station-grid/station-grid.component';
import _ from 'lodash';
import { SplitService } from 'src/app/core/split.service';
import { MIN_ROW_FRAME } from '../station-grid-const';
import { Title } from '@angular/platform-browser';
import { LibraryService } from 'src/app/core/library.service';
import { BoardService } from 'src/app/board/board.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { InfoDrawerComponent } from 'src/app/shared/info-drawer/info-drawer.component';
import { LibraryComponentListComponent } from 'src/app/shared/library-widget-drawer/library-component-list/library-component-list.component';
import { SubHeaderComponent } from 'src/app/shared/sub-header/sub-header.component';
import { SettingColumnComponent } from 'src/app/station/setting-column/setting-column.component';
import { MatInputModule } from '@angular/material/input';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

/**
 * Main component for viewing a station edit page.
 */
@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatSidenavModule,
    MatTabsModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    DragDropModule,
    MatTooltipModule,
    SubHeaderComponent,
    InfoDrawerComponent,
    forwardRef(() => StationGridComponent),
    SettingColumnComponent,
    RulesComponent,
    LoadingIndicatorComponent,
    ComponentTreeComponent,
    LibraryComponentListComponent,
    MatInputModule,
  ],
  styleUrls: ['./station.component.scss'],
})
export class StationComponent
  implements OnInit, OnDestroy, AfterContentChecked, CanNavigate, AfterViewInit
{
  /** The component for the drawer that houses comments and history. */
  @ViewChild('rightDrawer', { static: true })
  drawer!: MatDrawer;

  /** The component for the drawer that houses comments and history. */
  @ViewChild('libraryTree', { static: false })
  libraryTree!: LibraryTreeComponent;

  /** The component for the drawer that houses component tree. */
  @ViewChild('componentTree', { static: false })
  componentTree!: ComponentTreeComponent;

  /** The component for the drawer that houses component tree components. */
  @ViewChild('componentTreeComponents', { static: false })
  componentTreeComponents!: ComponentTreeComponent;

  /** The component for the drawer that houses component tree. */
  @ViewChild('stationGrid', { static: false })
  stationGrid!: StationGridComponent;

  /** Indicate error when saving flow rule. */
  @ViewChild(RulesComponent, { static: false })
  childFlowLogic!: RulesComponent;

  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Get station name from behaviour subject. */
  stationName = '';

  /** List of all text widget types. */
  readonly textWidgetTypes = [
    WidgetType.BodyWidget,
    WidgetType.TitleWidget,
    WidgetType.HeadlineWidget,
  ];

  /** List of all dashboard widget types. */
  readonly widgetDashboardType = [
    WidgetType.Station,
    WidgetType.StationTableBanner,
    WidgetType.StationMultiline,
    WidgetType.StationMultilineBanner,
    WidgetType.Container,
    WidgetType.ContainerListBanner,
    WidgetType.ContainerProfileBanner,
    WidgetType.StationGroupSearch,
    WidgetType.StationGroupTraffic,
    WidgetType.PreBuiltStation,
    WidgetType.PreBuiltContainer,
    WidgetType.GroupContainerTable,
  ];

  /** Station form. */
  stationForm!: FormGroup<{
    /** Station grid form. */
    stationGridForm: FormControl<string | null>;

    /** General Instructions field. */
    generalInstructions: FormControl<string | null>;

    /** Data Link question. */
    dataLinkForm: FormControl<string | null>;
  }>;

  /** The information about the station. */
  stationInformation!: StationInformation;

  /** Different types of input frames components.*/
  widgetType = WidgetType;

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** Question type. */
  questionFieldType = QuestionFieldType;

  /** Dashboard data, default dashboard general. */
  gridsterItemData!: DashboardData;

  /** Dashboard data Copy for save original data in mode edit. */
  gridsterItemDataCopy!: DashboardData;

  /** Station Rithm id. */
  stationRithmId = '';

  /** Flag self_assign. */
  showSelfAssign = false;

  /** The context of what is open in the drawer. */
  drawerContext = 'comments';

  /* The selected index of the container left section toggle button. */
  selectedInnerLeftIndex = '0';

  /** Contains all the component list types to display on the tree. */
  componentListType = StationComponentListType;

  /** Index for station tabs. */
  stationTabsIndex = 0;

  /** The current focused/selected widget. */
  widgetFocused = -1;

  /** Indicates when the button to move the widget will be enabled. */
  widgetMoveButton = -1;

  /** Index tab selected. */
  indexTab = 0;

  /** Select option in built drawer. */
  selectOptionInBuiltDrawer = 0;

  /** The selected tab index/init. */
  headerTabIndex = 0;

  /** Treatment data time picker. */
  dateFieldSettingsFlag = false;

  /** Feature flag for show Widget in Frames Update.*/
  showWidgetFramesUpdate = false;

  /** Show option the clone question and redirect question to originalStationId. */
  showDetachComponentFlag = false;

  /** Show option documentation-dialog. */
  showDocumentationDialogFlag = false;

  /** Feature flag Sql Integration. */
  showOptionSqlIntegration = false;

  /** Feature flag for show widget header and subheader. */
  headerFeatureFlag = false;

  /** Feature flag for show parent station link. */
  parentStationLinkFlag = false;

  /** Feature flag for show parent container link. */
  parentContainerLinkFlag = false;

  /** Flag to show Relationships. */
  showRelationshipActions = false;

  /** Flag to show append field action. */
  showAppendFieldActions = false;

  /** Flag to show action move container. */
  showActionMoveContainer = false;

  /** Flag advancedUpdateAction. */
  showAdvancedUpdateAction = false;

  /** Flag parent child relationship action. */
  showParentChildRelationship = false;

  /** Show or not custom fields option. */
  showFlowedBy = false;

  /** If the update field trigger must be shown. */
  showUpdateFieldTrigger = false;

  /** Feature flag show container station overlay link. */
  containerStationOverlayFeature = false;

  /** Feature flag Assigned to conditions. */
  showOptionAssignedToConditions = false;

  /** Feature flag to show the rules for Number field in conditions. */
  showNumberFieldRules = false;

  /** Feature flag for attachment field conditions. */
  attachmentFieldLogicFlag = false;

  /** Relationship widget flag. */
  relationshipWidgetFlag = false;

  /** Attachments conditions filters flag. */
  flagAttachmentsConditionsFilters = false;

  /** The list of all the input frames in the grid. */
  inputFrameList: string[] = [];

  /** The list of stations that follow this station. */
  forwardStations: ConnectedStationInfo[] = [];

  /** The list of stations that precede this station. */
  previousStations: ConnectedStationInfo[] = [];

  /** Appended Fields array. */
  appendedFields: ContainerNameField[] = [];

  /** Contains the rules received from Flow Logic to save them. */
  pendingFlowLogicRules: FlowLogicRule[] = [];

  /** Station Widgets array. */
  inputFrameWidgetItems: GridsterItemWidget[] = [];

  /** Old interface station data link widgets. */
  dataLinkArray: DataLinkObject[] = [];

  /** Current stations questions. */
  currentStationQuestions: Question[] = [];

  /** Show only button delete widget in drawer. */
  deleteWidget = false;

  /** Flag that renames the save button when the selected tab is Flow Logic. */
  isFlowLogicTab = false;

  /** Show Hidden accordion field private. */
  accordionFieldPrivateExpanded = false;

  /** Show Hidden accordion all field. */
  accordionFieldAllExpanded = false;

  /** Show alert of station empty. */
  showAlertStationEmpty = false;

  /** Edit mode toggle for station. */
  editMode = false;

  /** Flag that show if is layout mode. */
  layoutMode = true;

  /** Flag that show if is setting mode. */
  settingMode = false;

  /** Flag showing if the right drawer is open. */
  isOpenDrawerLeft = false;

  /** It is being used from the map view. */
  isUsedFromMap = false;

  /** Define if the user is either admin or owner of this station. */
  isAdminOrOwner = false;

  /** Disable flow logic tab when field question is saved. */
  disabledFlowTab = false;

  /** Grid initial values. */
  options: GridsterConfig = {
    gridType: 'verticalFixed',
    fixedRowHeight: 50,
    displayGrid: 'always',
    pushItems: true,
    pushResizeItems: true,
    draggable: {
      enabled: true,
      ignoreContent: true,
    },
    resizable: {
      enabled: true,
    },
    keepFixedHeightInMobile: true,
    itemResizeCallback: StationComponent.itemResize,
    margin: 12,
    minCols: 24,
    maxCols: 24,
    maxRows: 500,
    disableWarnings: true,
  };

  /** Loading / Error variables. */

  /** Whether the request to get the station info is currently underway. */
  stationLoading = false;

  /** Whether the request to get the widgets is currently underway. */
  widgetLoading = false;

  /** Whether the request to get connected stations is currently underway. */
  connectedStationsLoading = true;

  /** Circles in the gridster. */
  circlesWidget!: string;

  /** Saved station data link widgets. */
  savedDataLinkArray: DataLinkObject[] = [];

  /** Saved station data link widgets. */
  savedDataLinkArrayQuestions: Question[] = [];

  /** List of all components on a station bucket. */
  stationBucketComponents: StationBucketQuestion[] = [];

  /** Loading until all stations are returned. */
  stationDataLoading = false;

  /** Flag to indicate whether the focus is on a text component or not. */
  showTextAlignIcons = false;

  /** The text widget information object. */
  textWidgetInformation!: TextWidgetInformation;

  /** The text widget information object. */
  textWidgetAlignmentType = TextWidgetAlignmentType;

  configurableObject: ConfigurableObject | undefined = undefined;

  /* Question Icon. */
  questionFieldIcon = QuestionFieldIcon;

  /** Contains the selected widget data text to be configured. */
  textDataAlignment = {
    xAlign: 0,
    yAlign: 3,
    data: '',
  };

  /** Get the widget type for the setting tab. */
  widgetTypeToConfig!: WidgetType;

  /** Overlay for the gridster when loading an image in the widget. */
  stationOverlay = false;

  /** Show loading while add/delete station bucket questions. */
  loadingIndicator = false;

  /** Show loading while get libraries available. */
  loadingGetLibraries = false;

  /** It will store whether StationGridForm is touched or not. */
  isStationGridFormTouched = false;

  /** Available Libraries. */
  availableLibraries: StationAvailableLibrary[] = [];

  /** Array with the question types disabled.  */
  questionTypeDisabled: QuestionFieldType[] = [];

  /** Observable update station bucket questions. */
  bucketQuestionsUpdate$!: Observable<StandardStringJSON>;

  /** Observable for error station bucket questions. */
  bucketQuestionsError$!: Observable<unknown>;

  /** Array of question rithm ids. */
  questionRithmIds: string[] = [];

  /** Filter text for station library tab. */
  searchText = '';

  /** Tooltip for the left column - localComponents. */
  // eslint-disable-next-line max-len
  localComponentTooltip = `Local components are items that have been shared with the ${TermsGeneric.Station.Single.toLowerCase()} or are being used by the ${TermsGeneric.Station.Single.toLowerCase()}. To add to local, simply drag a component into the ${TermsGeneric.Station.Single.toLowerCase()} bucket. ${
    TermsGeneric.Container.Plural
    // eslint-disable-next-line max-len
  } that pass through this ${TermsGeneric.Station.Single.toLowerCase()} will collect data from the ${TermsGeneric.Station.Single.toLowerCase()} bucket.`;

  /** If library tree component data received or not. */
  libraryTreeDataReceived = false;

  /** If component tree data received or not. */
  componentTreeDataReceived = false;

  /** Copy all components on a station bucket list. */
  private stationBucketComponentsCopy: StationBucketQuestion[] = [];

  /** Filter text for station bucket tab. */
  stationBucketSearchText = '';

  /** Previous field component data received or not. */
  previousFieldTreeDataReceived = false;

  /** Whether updating Bucket Questions is underway. */
  updatingBucketLoading = false;

  /** If selected question is bucket question or not. */
  isBucketQuestion = false;

  /** If selected question is bucket question or not. */
  bucketQuestionNotInGrid = false;

  /** Readonly station fields. */
  readonly readonlyStationFields = [
    QuestionFieldType.StationName,
    QuestionFieldType.TimeInStation,
    QuestionFieldType.CreatedOn,
    QuestionFieldType.CreatedBy,
  ];

  /** Hide/show field settings section. */
  notConfigurableField = false;

  /** Will store all the questions stored within each frame. */
  frameQuestions: Question[] = [];

  /** Indicates whether or not the custom id stands out. */
  showCustomId = false;

  /** Show dragged mode when drag a item. */
  draggedStarted = false;

  /** Enable drop zone for station components. */
  showDropZoneStationComponent = false;

  /** Feature flag to widget libraries. */
  flagLibraryWidgets = false;

  /** Feature flag order of operations. */
  orderOfOperations = false;

  /** Feature Flag for station widgets updates. */
  stationWidgetUpdatesFlag = false;

  /** Library widgets. */
  allLibraries: Library[] = [];

  /** Search results. */
  searchResults: Library[] = [];

  /** Rearrange Options flag. */
  rearrangeOptionsFlag = false;

  /** If the panel is expanded. */
  isPanelExpanded = false;

  /** Show formula components. */
  showFormulaComponents = false;

  /** Feature Flag for show widget column. */
  widgetDataPhase2Flag = false;

  /** Feature Flag for sorting widget. */
  multiSortingFeatureFlag = false;

  /** Feature flag for rithmAI action. */
  rithmAIActionFlag = false;

  /** List of all bucket questions. */
  allBucketQuestions: StationBucketQuestion[] = [];

  constructor(
    private stationService: StationService,
    private containerService: ContainerService,
    private sidenavDrawerService: SidenavDrawerService,
    private errorService: ErrorService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private popupService: PopupService,
    private userService: UserService,
    private dialog: MatDialog,
    public mobileBrowserChecker: MobileBrowserChecker,
    private inputFrameHelper: InputFrame,
    private splitService: SplitService,
    private titleService: Title,
    @Inject(ChangeDetectorRef) private ref: ChangeDetectorRef,
    private libraryService: LibraryService,
    private boardService: BoardService,
  ) {
    this.windowResize();
  }

  /**
   * Check whether the library contains children or not.
   * @param index Index.
   * @param node Node of tree.
   * @returns A boolean.
   */
  hasChild = (index: number, node: CustomField | Question): boolean =>
    !!node.children && node.children.length > 0;

  /**
   * Listen the DrawerContext Service.
   */
  private subscribeDrawerContext(): void {
    this.sidenavDrawerService.drawerContext$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((context) => {
        this.drawerContext = context;
      });
  }

  /**
   * Listen the DocumentStationNameFields subject.
   */
  private subscribeContainerStationNameFields(): void {
    this.stationService.containerStationNameFields$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((appFields) => {
        this.appendedFields = appFields;
      });
  }

  /**
   * Listen the stationName subject.
   */
  private subscribeStationName(): void {
    this.stationService.stationName$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((stationName) => {
        this.stationName = stationName;
        this.titleService.setTitle(
          stationName.toString().split(' ').map(_.upperFirst).join(' '),
        );
        if (this.stationInformation) {
          this.stationInformation.name = stationName;
        }
      });
  }

  /**
   * Listen the StationFormTouched subject.
   */
  private subscribeStationFormTouched(): void {
    this.stationService.stationFormTouched$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.stationForm.get('stationGridForm')?.markAsTouched();
      });
  }

  /**
   * Listen the DataLink form is updated with saved data-links.
   */
  private subscribeDataLinkFormUnTouched(): void {
    this.stationService.dataLinkFormUnTouched$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.resetStationForm();
      });
  }

  /**
   * Listen the stationQuestion subject.
   */
  private subscribeStationQuestion(): void {
    this.stationService.stationQuestion$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((question) => {
        const prevQuestion = this.stationInformation.questions.find(
          (field) => field.rithmId === question.rithmId,
        );
        if (prevQuestion) {
          const questionIndex =
            this.stationInformation.questions.indexOf(prevQuestion);
          if (!question.isPossibleAnswer) {
            this.stationInformation.questions[questionIndex].prompt =
              question.prompt;
          } else {
            if (prevQuestion.possibleAnswers) {
              this.populatePossibleAnswers(
                question,
                questionIndex,
                prevQuestion.possibleAnswers,
              );
            }
          }
        }
      });
  }

  /**
   * Listen for added DataLinks object.
   */
  private subscribeStationDataLink(): void {
    this.stationService.dataLinkObject$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((dataLinkRetrieved) => {
        let dataLink = this.dataLinkArray.find(
          (dl) => dl.rithmId === dataLinkRetrieved.rithmId,
        );
        if (dataLink) {
          dataLink = dataLinkRetrieved;
        } else {
          this.dataLinkArray.push(dataLinkRetrieved);
        }
      });
  }

  /**
   * Listen to changes Flow Button Name.
   */
  private subscribeFlowButtonText$(): void {
    this.stationService.flowButtonText$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((flowButtonName) => {
        if (this.stationInformation && flowButtonName.length) {
          this.stationInformation.flowButton = flowButtonName;
        }
      });
  }

  /**
   * Populate and update possibleAnswers for an specific question.
   * @param answer The question we are adding possible answers to.
   * @param questionIndex The index of the current question in the station.
   * @param arrayAnswers The array of possible answers in the current question.
   */
  private populatePossibleAnswers(
    answer: Question,
    questionIndex: number,
    arrayAnswers: PossibleAnswer[] = [],
  ): void {
    if (
      Array.isArray(
        this.stationInformation.questions[questionIndex].possibleAnswers,
      )
    ) {
      const possibleAnswer = arrayAnswers.find(
        (a) => a.rithmId === answer.originalStationRithmId,
      );
      if (possibleAnswer) {
        possibleAnswer.text = answer.prompt;
        this.stationInformation.questions[questionIndex].possibleAnswers =
          arrayAnswers;
      } else {
        const newAnswer: PossibleAnswer = {
          rithmId: answer.originalStationRithmId || '',
          text: answer.prompt,
          default: false,
        };
        this.stationInformation.questions[questionIndex].possibleAnswers?.push(
          newAnswer,
        );
      }
    }
  }

  /**
   * Gets the widget libraries.
   */
  private getWidgetLibraries(): void {
    this.loadingGetLibraries = true;
    this.libraryService
      .getAvailableLibraries(this.stationRithmId)
      .pipe(
        first(),
        map((libraries) => {
          return libraries.map((library) => ({
            ...library,
            children: LibraryHelper.checkFieldsAvailability(
              [
                ...library.questions,
                ...LibraryHelper.generateWidgetComponents(library),
              ],
              this.frameQuestions,
            ),
            questions: library.questions.map((question) => ({
              ...question,
              originalLibraryRithmId: library.rithmId,
            })),
          }));
        }),
        map((libraries) => {
          if (!this.stationWidgetUpdatesFlag) {
            return libraries;
          }

          return [LibraryHelper.getDefaultLibrary('New Widgets'), ...libraries];
        }),
        map((libraries) => {
          return libraries.map((library) => {
            if (!this.flagLibraryWidgets || !this.relationshipWidgetFlag) {
              library.widgets = library.widgets?.filter(
                ({ widgetInfo: { widgetType } }) =>
                  this.flagLibraryWidgets && !this.relationshipWidgetFlag
                    ? widgetType !== WidgetType.RelationshipWidget
                    : this.relationshipWidgetFlag
                      ? widgetType === WidgetType.RelationshipWidget
                      : true,
              );
            }
            if (!this.isAdmin || !this.stationWidgetUpdatesFlag) {
              library.widgets = [];
            }
            return library;
          });
        }),
        tap(() => {
          this.loadingGetLibraries = false;
          this.libraryTreeDataReceived = true;
        }),
      )
      .subscribe({
        next: (libraries) => {
          const librariesFiltered =
            this.filterChildrenOfLibraryOfTypeAddressLine(libraries);
          this.searchResults = librariesFiltered;
          this.allLibraries = librariesFiltered;
        },
        error: () => {
          this.popupService.notify('Unable to get available libraries', true);
        },
      });
  }

  /**
   * Search component libraries.
   */
  searchComponents(): void {
    this.filterStationBucket();
    this.searchAvailableLibraries();
  }

  /**
   * Search widget libraries.
   */
  searchAvailableLibraries(): void {
    this.searchResults = LibraryHelper.searchLibraries(
      this.allLibraries,
      this.searchText,
      this.isAdmin && this.stationWidgetUpdatesFlag ? undefined : 'questions',
    );
    this.isPanelExpanded = this.searchText.length > 0;
  }

  /**
   * Set DragAndDrop CDK list for libraries or bucket.
   * @returns String array of lists connected.
   */
  get bucketConnectedListCDK(): string[] {
    const tempList = [...this.inputFrameList];
    return tempList;
  }

  /**
   * Get DragAndDrop CDK list for libraries.
   * @returns String array of lists connected.
   */
  get connectedListCDK(): string[] {
    return this.editMode
      ? [
          'bucket-zone',
          'bucket-zone-tree',
          ...this.inputFrameList.filter((id) =>
            id.includes('inputFrameWidget'),
          ),
        ]
      : ['bucket-zone', 'bucket-zone-tree'];
  }

  /**
   * Get DragAndDrop CDK list for local component.
   * @returns String array of lists connected.
   */
  get localComponentConnectedListCDK(): string[] {
    return this.inputFrameList.filter((id) => id.includes('inputFrameWidget'));
  }

  /**
   * Gets info about the document as well as forward and previous stations for a specific document.
   */
  ngOnInit(): void {
    this.stationForm = this.fb.group({
      stationGridForm: this.fb.control(''),
      generalInstructions: this.fb.control(''),
      dataLinkForm: this.fb.control(''),
    });
    this.isUsedFromMap = this.router.url.includes('/map');

    this.getTreatment();
    this.sidenavDrawerService.setDrawer(this.drawer);
    this.getParams();
    this.subscribeDrawerContext();
    this.subscribeContainerStationNameFields();
    this.subscribeStationName();
    this.subscribeStationFormTouched();
    this.subscribeDataLinkFormUnTouched();
    this.subscribeStationQuestion();
    this.subscribeStationDataLink();
    this.subscribeFlowButtonText$();
    this.setConfigMobileGridster();
  }

  /**
   * Attempts to retrieve the document info from the query params in the URL and make the requests.
   */
  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (params) => {
        if (!params.stationId) {
          this.handleInvalidParams();
        } else {
          this.configurableObject = undefined;
          this.stationRithmId = params.stationId;
          this.disableQuestions();
          this.getStationInfo(this.stationRithmId);
          this.getPreviousAndNextStations();
          this.displayDataLinks();
          this.getStationBucketQuestions();
          if (this.componentTree) {
            this.componentTree.stationRithmId = this.stationRithmId;
            this.componentTree.getStationComponents();
          }
          if (this.libraryTree && this.libraryTree.stationRithmId) {
            this.libraryTree.stationRithmId = this.stationRithmId;
            this.libraryTree.getAvailableLibraries &&
              this.libraryTree.getAvailableLibraries();
          }
        }
      },
      error: (error: unknown) => {
        this.errorService.displayError(
          "Something went wrong on our end and we're looking into it. Please try again in a little while.",
          error,
        );
      },
    });
  }

  /**
   * Get all the feature flags used in the component.
   */
  private getTreatment(): void {
    const orgRithmId = this.userService.user.organization;
    this.splitService.initSdk(orgRithmId);
    this.splitService.sdkReady$.pipe(first()).subscribe({
      next: () => {
        this.stationService.showDataTab$.next(
          this.splitService.getWidgetDataTab() === 'on',
        );
        this.dateFieldSettingsFlag =
          this.splitService.getDateFieldSettings() === 'on';
        this.showWidgetFramesUpdate =
          this.splitService.getWidgetFramesUpdate() === 'on';
        this.showDetachComponentFlag =
          this.splitService.getDetachComponent() === 'on';
        this.showDocumentationDialogFlag =
          this.splitService.getDocumentationDialog() === 'on';
        this.headerFeatureFlag = this.splitService.getWidgetHeader() === 'on';
        this.parentStationLinkFlag =
          this.splitService.getParentStationLink() === 'on';
        this.parentContainerLinkFlag =
          this.splitService.getParentContainerLink() === 'on';
        this.showOptionSqlIntegration =
          this.splitService.getSqlIntegration() === 'on';
        this.showOptionAssignedToConditions =
          this.splitService.getAssignedToConditions() === 'on';
        this.flagLibraryWidgets =
          this.splitService.getFlagLibraryWidgets() === 'on';
        this.orderOfOperations =
          this.splitService.getOrderOfOperations() === 'on';
        this.rearrangeOptionsFlag =
          this.splitService.getRearrangeOptions() === 'on';
        this.showNumberFieldRules =
          this.splitService.getNumberFieldRules() === 'on';
        this.showSelfAssign = this.splitService.getSelfAssign() === 'on';
        this.attachmentFieldLogicFlag =
          this.splitService.getAttachmentRules() === 'on';
        this.relationshipWidgetFlag =
          this.splitService.getRelationshipWidget() === 'on';
        this.showRelationshipActions =
          this.splitService.getRelationshipActionsTreatment() === 'on';
        this.showAppendFieldActions =
          this.splitService.getAppendFieldActionTreatment() === 'on';
        this.showActionMoveContainer =
          this.splitService.getActionMoveContainer() === 'on';
        this.showAdvancedUpdateAction =
          this.splitService.getAdvancedUpdateAction() === 'on';
        this.showParentChildRelationship =
          this.splitService.getParentChildRelationship() === 'on';
        this.showFlowedBy = this.splitService.getAssignToFlowedBy() === 'on';
        this.showUpdateFieldTrigger =
          this.splitService.getFieldUpdateTrigger() === 'on';
        this.showFormulaComponents =
          this.splitService.getCalculationField() === 'on';
        this.containerStationOverlayFeature =
          this.splitService.containerStationOverlayLink() === 'on';
        this.widgetDataPhase2Flag =
          this.splitService.getWidgetDataTabPhase2() === 'on';
        this.multiSortingFeatureFlag =
          this.splitService.getMultiTierSorting() === 'on';
        this.stationWidgetUpdatesFlag =
          this.splitService.getStationWidgetUpdates() === 'on';
        this.flagAttachmentsConditionsFilters =
          this.splitService.getAttachmentsConditionsFilters() === 'on';
        this.rithmAIActionFlag = this.splitService.getRithmAIAction() === 'on';
      },
    });
  }

  /**
   * Return if can navigate.
   * @returns If can navigate.
   */
  public canNavigate(): boolean {
    return this.stationGrid ? !this.stationGrid.editMode : true;
  }

  /**
   * Disable questions.
   */
  private disableQuestions(): void {
    this.questionTypeDisabled.push(QuestionFieldType.Phone);
    this.questionTypeDisabled.push(QuestionFieldType.LongText);
    this.questionTypeDisabled.push(QuestionFieldType.Checkbox);
  }

  /**
   * Gridster resize item event.
   * @param item Current resized item.
   * @param itemComponent Item Interface.
   */
  static itemResize(
    item: GridsterItem,
    itemComponent: GridsterItemComponentInterface,
  ): void {
    if (item.type === WidgetType.CircleImageWidget) {
      const itemTo: GridsterItem = itemComponent.$item;
      if (itemTo.rows < item.rows || itemTo.cols < item.cols) {
        itemTo.cols = itemTo.rows < item.rows ? itemTo.rows : itemTo.cols;
        itemTo.rows = itemTo.cols < item.cols ? itemTo.cols : itemTo.rows;
      }

      if (itemTo.rows > item.rows || itemTo.cols > item.cols) {
        itemTo.cols = itemTo.rows > item.rows ? itemTo.rows : itemTo.cols;
        itemTo.rows = itemTo.cols > item.cols ? itemTo.cols : itemTo.rows;
      }
    }
  }

  /**
   * Get whether widget type text or not.
   * @returns A boolean.
   */
  get widgetTypeText(): boolean {
    return this.textWidgetTypes.includes(this.widgetTypeToConfig);
  }

  /** Comment. */
  ngAfterContentChecked(): void {
    this.ref.detectChanges();
  }

  /**
   * AfterViewInit Method.
   */
  ngAfterViewInit(): void {
    if (!this.showDropZoneStationComponent) {
      this.inputFrameList.push('input-fields-libraries');
      this.inputFrameList.push('input-station-bucket');
    }
  }

  /**
   * Click for tab selected item inside sub-header.
   * @param headerTabIndex To catch event that verify click tab selected item.
   */
  headerSelectedTab(headerTabIndex: number): void {
    this.headerTabIndex = headerTabIndex;
    this.indexTab = headerTabIndex;
    this.configurableObject = undefined;
    if (!headerTabIndex) {
      /** Every time we go from the Rules tab to Container we need to set the previous grid mode. */
      setTimeout(() => {
        this.stationService.editModeOnGrid$.next(this.editMode);
      }, 500);
    }
  }

  /**
   * Whether to show the backdrop for the comment and history drawers.
   * @returns Whether to show the backdrop.
   */
  get drawerHasBackdrop(): boolean {
    return this.sidenavDrawerService.drawerHasBackdrop;
  }

  /**
   * Whether the signed in user is an admin or not.
   * @returns True if the user is an admin, false otherwise.
   */
  get isAdmin(): boolean {
    return this.userService.user.role === 'admin';
  }

  /**
   * Validate the conditions to display the Save or Save Rules button.
   * @returns If display the button, can be true or false.
   */
  get disableSaveButton(): boolean {
    return (
      // If current tab is document and form field values are not changed.
      (!this.isFlowLogicTab &&
        (!this.stationForm.valid ||
          !(
            this.stationForm.dirty ||
            this.stationForm.controls.stationGridForm.touched ||
            this.stationForm.controls.dataLinkForm.touched
          ))) ||
      // If current tab is flow and there are no pending flow rules.
      (this.pendingFlowLogicRules.length === 0 && this.isFlowLogicTab)
    );
  }

  /**
   * Whether the screen width is lesser than 640px.
   * @returns True if width is lesser than 640px.
   */
  get isMobileView(): boolean {
    return window.innerWidth <= 640;
  }

  /**
   * Whether the screen width is lesser than 640px.
   * @returns True if width is lesser than 640px.
   */
  get nonEditableStation(): boolean {
    return window.innerWidth <= 1024;
  }

  /**
   * Validate the conditions to display the Save or Save Rules button.
   * @returns If display the button, can be true or false.
   */
  get stationInputFrames(): Question[] {
    let dataFiltered = [] as Question[];
    if (this.inputFrameWidgetItems) {
      this.inputFrameWidgetItems.map((frame) => {
        if (frame?.questions && frame.widgetType === WidgetType.InputWidget) {
          dataFiltered = dataFiltered.concat(frame.questions);
        }
      });
    }

    return dataFiltered;
  }

  /**
   * Whether the drawer is open.
   * @returns True if the drawer is open, false otherwise.
   */
  get isDrawerOpen(): boolean {
    return this.sidenavDrawerService.isDrawerOpen;
  }

  /**
   * Navigates the user back to dashboard and displays a message about the invalid params.
   */
  private handleInvalidParams(): void {
    this.navigateBack();
    this.errorService.displayError(
      'The link you followed is invalid. Please double check the URL and try again.',
      new Error(
        `Invalid params for ${TermsGeneric.Container.Single.toLowerCase()}.`,
      ),
    );
  }

  /**
   * Navigates the user back to the dashboard page.
   */
  private navigateBack(): void {
    // TODO: [RIT-691] Check which page user came from. If exists and within Rithm, navigate there
    // const previousPage = this.location.getState();
    // If no previous page, go to dashboard
    this.router.navigateByUrl(TermsGeneric.Board.Lower.Plural);
  }

  /**
   * Remove excess spaces, respecting line breaks.
   * @param text The text to be evaluated to remove excess spaces.
   * @returns A string to assign a character.
   */
  removeSpaces(text: string): string {
    return text.replace(/(?!\n)\s{2,}/g, ' ');
  }

  /**
   * Get data about the document and station the document is in.
   * @param stationId The id of the station that the document is in.
   */
  private getStationInfo(stationId: string): void {
    this.stationLoading = true;
    this.stationService
      .getStationInfo(stationId)
      .pipe(first())
      .subscribe({
        next: (stationInfo) => {
          if (stationInfo) {
            this.stationService.requiredAssign(stationInfo.assignmentRequired);
            this.stationInformation = stationInfo;
            this.stationName = stationInfo.name;
            this.stationForm.controls.generalInstructions.setValue(
              stationInfo.instructions,
            );
            this.stationService.updateCurrentStationQuestions(
              this.stationInformation.questions,
            );

            this.getStationWidgets();
            this.getWidgetLibraries();
            this.stationService.stationName$.next(this.stationName);
          }
          this.resetStationForm();
          this.stationInformation.flowButton =
            stationInfo.flowButton || this.termsGeneric.Flow.Single;
          this.stationLoading = false;

          this.stationService.currentStationQuestions$.next(
            this.stationInputFrames,
          );
          // We build the object based on the stationInfo object.
          const currentStationObj: StationOrGroup = {
            rithmId: stationId,
            name: stationInfo.name,
            type: 'station',
            description: stationInfo.description,
          };
          this.stationService.updateCurrentStationOption(currentStationObj);

          this.getIsAdminOrOwner();
        },
        error: (error: unknown) => {
          this.navigateBack();
          this.stationLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Adds selected fieldType to field array.
   * @param fieldType The field to add.
   */
  addQuestion(fieldType: QuestionFieldType): void {
    const newQuestion: Question = {
      rithmId: uuidv4(),
      prompt: '',
      questionType: fieldType,
      isReadOnly: false,
      isRequired: fieldType === QuestionFieldType.Instructions,
      isPrivate: false,
      children:
        fieldType === QuestionFieldType.AddressLine
          ? this.addAddressChildren()
          : [],
      originalStationRithmId: this.stationRithmId,
    };
    if (
      fieldType === QuestionFieldType.CheckList ||
      fieldType === QuestionFieldType.Select ||
      fieldType === QuestionFieldType.MultiSelect
    ) {
      newQuestion.possibleAnswers = [];
    }
    this.stationInformation.questions.push(newQuestion);
    this.stationService.touchStationForm();
  }

  /**
   * Save datalink objects when they exists (old interface).
   */
  saveDataLinks(): void {
    const framesForDatalink: GridsterItemWidget[] = [];
    /** Build a frame for each existing datalink. */
    this.dataLinkArray.forEach((dl) => {
      const elementRithmId = uuidv4();
      dl.frameRithmId = elementRithmId;
      const frameGrid = {
        rithmId: elementRithmId,
        cols: 24,
        rows: 4,
        x: 0,
        y: 0,
        widgetType: WidgetType.DataLinkWidget,
        data: JSON.stringify(dl),
        id: this.inputFrameWidgetItems.length,
      };
      framesForDatalink.push(frameGrid);
    });
    this.stationService
      .saveDataLinkFrames(this.stationRithmId, framesForDatalink)
      .pipe(first())
      .subscribe({
        next: (frames) => {
          if (frames && frames.length) {
            const requestRow: Observable<DataLinkObject>[] = [];
            Promise.all(
              this.dataLinkArray.map(async (dl) => {
                requestRow.push(
                  this.containerService.saveDataLink(this.stationRithmId, dl),
                );
              }),
            );
            this.forkJoinDataLink(requestRow);
          }
        },
        error: (error: unknown) => {
          this.stationLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Execute a fork join to save dataLink.
   * @param requestRow Request row to be executed.
   */
  private forkJoinDataLink(requestRow: Observable<DataLinkObject>[]): void {
    forkJoin(requestRow)
      .pipe(first())
      .subscribe({
        error: (error: unknown) => {
          this.stationLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Get is user is admin or worker or owner in station.
   */
  private getIsAdminOrOwner(): void {
    this.isAdminOrOwner = this.userService.isAdmin
      ? true
      : !!this.stationInformation.stationOwners?.find(
          (owner) => owner.rithmId === this.userService.user.rithmId,
        );
  }

  /**
   * Save Station information and executed petitions to api.
   *
   */
  saveStationInformation(): void {
    this.stationLoading = true;
    this.disabledFlowTab = true;
    if (this.dataLinkArray.length) {
      this.saveDataLinks();
      this.stationInformation.questions =
        this.stationInformation.questions.filter(
          (q) => q.questionType !== QuestionFieldType.DataLink,
        );
    }
    if (this.stationForm.controls.generalInstructions.value) {
      this.stationForm.controls.generalInstructions.setValue(
        this.removeSpaces(this.stationForm.controls.generalInstructions.value),
      );
    }
    const petitionsUpdateStation = [
      // Update appended fields to document.
      this.stationService.updateContainerNameGrid(
        this.stationInformation.rithmId,
        this.appendedFields,
      ),

      // Update general instructions.
      this.stationService.updateStationGeneralInstructions(
        this.stationInformation.rithmId,
        this.stationForm.controls.generalInstructions.value || '',
      ),
    ];

    if (this.stationForm.get('stationGridForm')?.touched) {
      petitionsUpdateStation.push(
        // Update Questions.
        this.stationService.updateStationQuestions(
          this.stationInformation.rithmId,
          this.stationInformation.questions,
        ),
      );
    }

    forkJoin(petitionsUpdateStation)
      .pipe(first())
      .subscribe({
        next: ([, , stationQuestions]) => {
          this.stationLoading = false;
          this.stationInformation.name = this.stationName;
          if (stationQuestions) {
            //in case of save/update questions the station questions object is updated.
            this.stationInformation.questions = stationQuestions as Question[];
          }
          this.resetStationForm();
          setTimeout(() => (this.disabledFlowTab = false), 2000);
          this.popupService.notify(
            `${TermsGeneric.Station.Single} successfully saved`,
          );
        },
        error: (error: unknown) => {
          this.stationLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Save flow Logic Rules when is tab FlowLogic.
   *
   */
  saveFlowLogicRules(): void {
    this.childFlowLogic.ruleLoading = true;
    this.containerService
      .saveStationFlowLogic(this.pendingFlowLogicRules)
      .pipe(first())
      .subscribe({
        next: () => {
          this.pendingFlowLogicRules = [];
          this.childFlowLogic.ruleLoading = false;
          this.resetStationForm();
        },
        error: (error: unknown) => {
          this.stationLoading = false;
          this.childFlowLogic.ruleLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Get previous and following stations.
   *
   */
  getPreviousAndNextStations(): void {
    this.stationService
      .getPreviousAndNextStations(this.stationRithmId)
      .pipe(first())
      .subscribe({
        next: (prevAndNextStations) => {
          if (prevAndNextStations) {
            this.forwardStations = prevAndNextStations.nextStations;
            this.previousStations = prevAndNextStations.previousStations;
          }
        },
        error: (error: unknown) => {
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Move previous field from private/all expansion panel to the grid area.
   * @param question The question that was moved from private/all.
   */
  movePreviousFieldToGrid(question: Question): void {
    this.stationInformation.questions.push(question);
    this.stationService.touchStationForm();
  }

  /** This cancel button clicked show alert. */
  async cancelStation(): Promise<void> {
    const response = await this.popupService.confirm({
      title: 'Are you sure?',
      message: `Your changes will be lost and you will return to the ${TermsGeneric.Board.Lower.Single}.`,
      okButtonText: 'Confirm',
      cancelButtonText: 'Close',
      important: true,
    });
    if (response) {
      this.router.navigateByUrl(TermsGeneric.Board.Lower.Plural);
    }
  }

  /**
   * Add children when the parent is an Address field type.
   * @returns Address children questions.
   */
  private addAddressChildren(): Question[] {
    const addressChildren: Question[] = [];
    const children = [
      {
        prompt: 'Address Line 1',
        type: QuestionFieldType.LongText,
        required: true,
      },
      {
        prompt: 'Address Line 2',
        type: QuestionFieldType.LongText,
        required: false,
      },
      { prompt: 'City', type: QuestionFieldType.City, required: true },
      { prompt: 'State', type: QuestionFieldType.State, required: true },
      { prompt: 'Zip', type: QuestionFieldType.Zip, required: true },
    ];
    children.forEach((element) => {
      const child: Question = {
        rithmId: uuidv4(),
        prompt: element.prompt,
        questionType: element.type,
        isReadOnly: false,
        isRequired: element.required,
        isPrivate: false,
        children: [],
        originalStationRithmId: this.stationRithmId,
      };
      addressChildren.push(child);
    });
    return addressChildren;
  }

  /**
   * Detect tabs changed.
   * @param tabChangeEvent Receives the detail from tab selected.
   */
  tabSelectedChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.isFlowLogicTab = tabChangeEvent.index === 1;
    // Fix for the issue with stationGridForm control's touched property(updating to TRUE when there are no changes in the form).
    if (this.isFlowLogicTab) {
      this.isStationGridFormTouched =
        this.stationForm.controls.stationGridForm.touched;
    }
    if (!this.isFlowLogicTab && !this.isStationGridFormTouched) {
      setTimeout(() => {
        this.stationForm.controls.stationGridForm.markAsUntouched();
      }, 10);
    }
  }

  /**
   * Change options in grid.
   *
   */
  changedOptions(): void {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  /**
   * Receives a flow logic rule.
   * @param flowLogicRule Contains a flow logic rules of the current station.
   */
  addFlowLogicRule(flowLogicRule: FlowLogicRule | null): void {
    if (flowLogicRule) {
      const flowLogicStation = this.pendingFlowLogicRules.findIndex(
        (flowRule) =>
          flowRule.destinationStationRithmID ===
            flowLogicRule.destinationStationRithmID &&
          flowRule.stationRithmId === flowLogicRule.stationRithmId,
      );
      if (flowLogicStation >= 0) {
        this.pendingFlowLogicRules[flowLogicStation] = flowLogicRule;
      } else {
        this.pendingFlowLogicRules.push(flowLogicRule);
      }
    } else {
      this.pendingFlowLogicRules = [];
    }
  }

  /**
   * Get the station frame widgets.
   */
  getStationWidgets(): void {
    this.widgetLoading = true;
    this.stationService
      .getStationWidgets(this.stationRithmId)
      .pipe(first())
      .subscribe({
        next: (inputFrames) => {
          this.inputFrameWidgetItems = [];
          this.inputFrameList = [];
          /**Add individual properties for every Type. */
          const gridCols = this.options.minCols ? this.options.minCols : 24;
          inputFrames?.forEach((frame) => {
            const frameData = JsonValidator.getObjectFromString(frame.data);
            /**
             * ?The next line will set each id in the order items should be displayed in responsive view.
             * ?This will start counting elements from left-to-right and then from top=to=bottom.
             */
            frame.id = frame.y === 0 ? frame.x : gridCols * frame.y + frame.x;
            switch (frame.widgetType) {
              case WidgetType.InputWidget:
                frame.minItemCols = 6;
                frame.minItemRows = MIN_ROW_FRAME;

                // Validate to stablish row to frame and height to questions.
                if (frame.questions?.length) {
                  this.inputFrameHelper.setHeightByDefaultToQuestions(frame);
                  frame.minItemRows = this.inputFrameHelper.calculateRowFrame(
                    frame.questions,
                  );
                  frame.rows =
                    frame.rows >= frame.minItemRows
                      ? frame.rows
                      : frame.minItemRows;
                }

                frame.data =
                  frame.data ||
                  JSON.stringify({
                    stationRithmId: this.stationRithmId,
                  });
                this.inputFrameList.push('inputFrameWidget-' + frame.rithmId);
                break;
              case WidgetType.BodyWidget:
                frame.minItemCols = 3;
                frame.minItemRows = MIN_ROW_FRAME;
                delete frame.questions;
                break;
              case WidgetType.HeadlineWidget:
              case WidgetType.TitleWidget:
                frame.minItemCols = 4;
                frame.minItemRows = MIN_ROW_FRAME;
                delete frame.questions;
                break;
              case WidgetType.ImageWidget:
                frame.minItemCols = 6;
                frame.minItemRows = 2;
                delete frame.questions;
                break;
              case WidgetType.CircleImageWidget:
                frame.minItemCols = 4;
                frame.minItemRows = 4;
                delete frame.questions;
                break;
              case WidgetType.CustomIdWidget:
                frame.minItemCols = 6;
                frame.maxItemRows = 1;
                delete frame.questions;
                break;
              case WidgetType.ParentChildWidget:
                frame.minItemCols = 6;
                frame.minItemRows = 3;
                delete frame.questions;
                break;

              case WidgetType.Container:
              case WidgetType.Station:
              case WidgetType.StationGroupSearch:
              case WidgetType.StationGroupTraffic:
              case WidgetType.GroupContainerTable:
                frame.minItemCols = 6;
                frame.minItemRows = 4;
                delete frame.questions;
                break;
              case WidgetType.ContainerListBanner:
              case WidgetType.ContainerProfileBanner:
              case WidgetType.StationMultiline:
              case WidgetType.StationMultilineBanner:
              case WidgetType.StationTableBanner:
              case WidgetType.RelationshipWidget:
                frame.minItemCols = 6;
                frame.minItemRows = 6;
                frame.imageId = frameData?.imageId;
                frame.imageName = frameData?.imageName;
                delete frame.questions;
                break;
              case WidgetType.PreBuiltStation:
              case WidgetType.PreBuiltContainer:
                frame.minItemCols = 8;
                frame.minItemRows = 4;
                delete frame.questions;
                break;
              default:
                break;
            }
            this.inputFrameWidgetItems.push(frame);
            this.changedOptions();
          });
          this.inputFrameWidgetItems.sort(function (a, b) {
            return a.id !== undefined && b.id !== undefined
              ? a.id > b.id
                ? 1
                : -1
              : 0;
          });
          this.stationService.inputFrameWidgetItemsCopy(
            this.inputFrameWidgetItems,
          );
          this.stationService.inputFrameListCopy(this.inputFrameList);
          this.stationService.currentStationQuestions$.next(
            this.stationInputFrames,
          );
          this.checkBucketQuestions();
          this.widgetLoading = false;
        },
        error: (error: unknown) => {
          this.widgetLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Toggles the open state for drawer mode.
   */
  toggleLeftDrawer(): void {
    this.isOpenDrawerLeft = !this.isOpenDrawerLeft;
    if (this.settingMode) {
      this.showTextAlignIcons = false;
    }
  }

  /**
   * Open in the right column the configuration of the selected widget.
   * @param objectToConfig The object to be configured.
   * @param _containerWidgetType The type of the configurable object's container.
   * @param isBucketQuestion If the question is belongs to bucket or not.
   * @param bucketQuestionNotInGrid If the question is belongs to bucket or not.
   */
  openSettingTab(
    objectToConfig:
      | Question
      | CustomIdSettings
      | GridsterItemWidget
      | StationBucketQuestion,
    _containerWidgetType: WidgetType,
    isBucketQuestion = false,
    bucketQuestionNotInGrid = false,
  ): void {
    this.configurableObject = undefined;
    this.widgetTypeToConfig = _containerWidgetType;
    let containerIndex = -1;
    let customData: GridsterItemWidget;
    let frame: GridsterItemWidget | undefined = undefined;
    switch (_containerWidgetType) {
      case WidgetType.BodyWidget:
      case WidgetType.HeadlineWidget:
      case WidgetType.TitleWidget:
      case WidgetType.ImageWidget:
        frame = this.inputFrameWidgetItems.find(
          (e) => e.rithmId === (objectToConfig as GridsterItemWidget).rithmId,
        );
        if (frame) {
          containerIndex = this.inputFrameWidgetItems.indexOf(frame);
        }
        break;
      case WidgetType.CustomIdWidget:
        this.inputFrameWidgetItems.forEach((element) => {
          if (element.widgetType === WidgetType.CustomIdWidget) {
            customData = JSON.parse(element.data);
            if (
              customData.rithmId ===
              (objectToConfig as CustomIdSettings).rithmId
            ) {
              frame = element;
            }
          }
        });
        if (frame) {
          containerIndex = this.inputFrameWidgetItems.indexOf(frame);
        }
        break;

      case WidgetType.InputWidget:
        this.inputFrameWidgetItems.forEach((e, index) => {
          if (
            e.questions?.some(
              (q) =>
                q.rithmId ===
                (objectToConfig as Question | StationBucketQuestion).rithmId,
            )
          ) {
            containerIndex = index;
            frame = e;
          }
        });
        break;
    }

    setTimeout(() => {
      this.configurableObject = {
        target: objectToConfig,
        containerIndex: containerIndex,
        containerWidgetType: _containerWidgetType,
        isBucketQuestion: isBucketQuestion,
        isOnTheGrid: !bucketQuestionNotInGrid,
      };
    }, 100);

    this.isBucketQuestion = isBucketQuestion;
    this.bucketQuestionNotInGrid = bucketQuestionNotInGrid;
  }

  /**
   * Close the right setting drawer for field setting.
   */
  closeSettingDrawer(): void {
    /** If both are open, the field setting drawer must be closed. */
    if (
      this.sidenavDrawerService.isDrawerOpen &&
      this.drawerContext === 'fieldSetting'
    ) {
      this.sidenavDrawerService.closeDrawer();
    }
  }

  /**
   * Resets the station form.
   */
  private resetStationForm() {
    setTimeout(() => {
      this.stationForm.markAsPristine();
      this.stationForm.controls.stationGridForm.markAsUntouched();
      this.stationForm.controls.dataLinkForm.markAsUntouched();
    }, 0);
  }

  /**
   * Display the saved data links.
   */
  private displayDataLinks(): void {
    this.stationService
      .getDataLinks(this.stationRithmId)
      .pipe(first())
      .subscribe({
        next: (dataLinks) => {
          this.savedDataLinkArray = dataLinks;
          for (let i = 0; i < this.savedDataLinkArray.length; i++) {
            const newQuestion: Question = {
              rithmId: uuidv4(),
              prompt: '',
              questionType: QuestionFieldType.DataLink,
              isReadOnly: false,
              isRequired: false,
              isPrivate: false,
              children: [],
              originalStationRithmId: this.stationRithmId,
            };
            this.savedDataLinkArrayQuestions.push(newQuestion);
          }
        },
        error: (error: unknown) => {
          this.stationDataLoading = false;
          this.errorService.displayError(
            `Failed to get all ${TermsGeneric.Station.Plural.toLowerCase()} for this data link field.`,
            error,
            false,
          );
        },
      });
    if (this.stationService.allStations$.value.length === 0) {
      this.getAllStations();
    }
  }

  /**
   * Get the list of all stations.
   */
  private getAllStations(): void {
    this.stationDataLoading = true;
    this.stationService
      .getAllStationsOptimized()
      .pipe(first())
      .subscribe({
        next: (stations) => {
          this.stationService.allStations$.next(stations);
          this.stationDataLoading = false;
        },
        error: (error: unknown) => {
          this.stationDataLoading = false;
          this.errorService.displayError(
            `Failed to get all ${TermsGeneric.Station.Plural.toLowerCase()} for this data link field.`,
            error,
            false,
          );
        },
      });
  }

  /**
   * Get limit of page by rows of widget.
   * @param widget The widget for evaluate.
   * @returns The limit to list items on widget.
   */
  limitListPerPage(widget: GridsterItemWidget): number {
    return widget.rows * 2;
  }

  /**
   * Activate edit mode and highlight the Custom Id.
   */
  standOutCustomId(): void {
    this.headerTabIndex = 0;
    this.indexTab = 0;
    this.selectedInnerLeftIndex = '0';

    /** Highlight the customId in the tree and activate edit mode to add the customId to the grid. */
    this.showCustomId = true;
    setTimeout(() => {
      this.showCustomId = false;
    }, 10000);

    this.stationService.editModeOnGrid$.next(true);
  }

  /**
   * Toggle drawer of the station widget.
   * @param widgetItem String of the data station.
   * @param widgetIndex Number of the position the widget.
   * @param quantityElementsWidget Number of items to be displayed in the widget.
   */
  openDashboardWidgetSetting(
    widgetItem: GridsterItemWidget,
    widgetIndex: number,
    quantityElementsWidget: number,
  ): void {
    const objectToConfig: EditDataWidget = {
      widgetItem: widgetItem,
      widgetIndex: widgetIndex,
      quantityElementsWidget: quantityElementsWidget,
      deleteWidget: this.deleteWidget,
      headerFeatureFlag: this.headerFeatureFlag,
    };

    const frameIndex = this.inputFrameWidgetItems.findIndex(
      ({ rithmId }) => rithmId === widgetItem.rithmId,
    );
    if (frameIndex > -1) {
      this.configurableObject = {} as ConfigurableObject;
      setTimeout(() => {
        this.configurableObject = {
          target: objectToConfig,
          containerIndex: widgetIndex,
          containerWidgetType: widgetItem.widgetType,
          quantityElementsWidget: quantityElementsWidget,
        };
      }, 100);

      if (
        LibraryHelper.genericWidgetTypes.includes(
          widgetItem.widgetType as GenericWidget,
        )
      )
        this.boardService.flagLibraryWidgets$.next(this.flagLibraryWidgets);
    }
  }

  /**
   * Needed to resize a mobile browser when the scrollbar hides.
   */
  windowResize(): void {
    fromEvent(window, 'resize')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.setConfigMobileGridster();
      });
  }

  /** Set config break point in mobile. */
  private setConfigMobileGridster(): void {
    this.options.mobileBreakpoint = this.mobileBrowserChecker.isMobileDevice()
      ? 1920
      : 768;
    this.changedOptions();
  }

  /**
   * Get library available.
   */
  getStationAvailableLibraries(): void {
    this.loadingGetLibraries = true;
    this.stationService
      .getStationAvailableLibraries(this.stationRithmId)
      .pipe(first())
      .subscribe({
        next: (libraries) => {
          this.availableLibraries = libraries;
          this.loadingGetLibraries = false;
        },
        error: (error: unknown) => {
          this.loadingGetLibraries = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Open station library modal.
   */
  async openStationLibraryModal(): Promise<void> {
    this.dialog.closeAll();
    const dialog = await this.dialog.open(StationLibraryModalComponent, {
      panelClass: ['w-5/6', 'sm:w-4/5'],
      height: '640px',
      maxWidth: '1024px',
      data: {
        stationName: this.stationInformation.name,
        stationRithmId: this.stationRithmId,
        widgetLibraries: this.allLibraries,
        localWidgets: this.inputFrameWidgetItems.filter(
          ({ widgetType }) =>
            !ComponentHelper.unpublishableWidgets.includes(widgetType),
        ),
      },
      disableClose: true,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog
      .afterClosed()
      .pipe(first())
      .subscribe((reloadLibraryList: boolean) => {
        if (reloadLibraryList && this.libraryTree && this.componentTree) {
          this.componentTree.getStationComponents();
          this.getStationWidgets();
          this.libraryTree.getAvailableLibraries &&
            this.libraryTree.getAvailableLibraries();
          this.getWidgetLibraries();
        }
      });
  }

  /**
   * Add col at widget current.
   * @param indexResizeWidget The index widget selected.
   */
  widgetTextAdjustment(indexResizeWidget: number): void {
    this.stationGrid?.widgetTextAdjustment(indexResizeWidget);
  }

  /**
   * Open station bucket modal about detail.
   */
  openStationBucketModal(): void {
    const dialog = this.dialog.open(StationBucketModalComponent, {
      panelClass: ['w-5/6', 'sm:w-4/5'],
      maxWidth: '1024px',
      height: '580px',
      disableClose: true,
      data: {
        stationName: this.stationInformation.name,
        stationRithmId: this.stationRithmId,
      },
    });
    dialog
      .afterClosed()
      .pipe(first())
      .subscribe((bucketQuestion) => {
        this.loadingIndicator = true;
        if (bucketQuestion?.bucketHasBeenUpdated) {
          this.stationBucketComponents = bucketQuestion.data;
          this.checkBucketQuestions();
        }
        this.loadingIndicator = false;
      });
  }

  /**
   * Enable bucket search option once data is received.
   * @returns True once data has been received else false.
   */
  get enableBucketSearchText(): boolean {
    return this.loadingIndicator || !this.previousFieldTreeDataReceived;
  }

  /**
   * Filters the bucket list based on search text.
   */
  filterStationBucket(): void {
    if (this.stationBucketSearchText.trim()) {
      this.stationBucketComponents = this.stationBucketComponentsCopy.filter(
        (e) =>
          e.prompt
            .toLowerCase()
            .includes(this.stationBucketSearchText.toLowerCase()),
      );
    } else {
      this.stationBucketComponents =
        this.stationService.stationBucketQuestionsCopy(
          this.stationBucketComponentsCopy,
        );
    }
  }

  /**
   * Will add a new item when a component is dropped into Station bucket.
   * @param event Information about dropping component.
   */
  dropStationBucket(event: CdkDragDrop<Question[]>): void {
    //If we populate this array we'll be updating the field.
    const questionRithmIds: string[] = [];
    //If we populate this array then we'll be adding a new field to the bucket.
    const questionsBucket: Question[] = [];
    if (!this.updatingBucketLoading) {
      event.previousContainer.id === 'input-station-bucket'
        ? (this.componentTreeComponents.disableQuestion =
            event.item.dropContainer.data[0].questionType)
        : (this.libraryTree.disableQuestionRithmId =
            event.item.dropContainer.data[0].rithmId);

      this.draggedStarted = true;
      this.updatingBucketLoading = true;
      const questionInfo = _.cloneDeep(event.previousContainer.data[0]);

      questionInfo.rithmId = questionInfo.rithmId || uuidv4();
      questionInfo.settings = '';

      questionInfo.questionType === QuestionFieldType.CustomId &&
        (this.showCustomId = false);

      //If there is a libraryId or originalId it means that the question already exists.
      if (
        questionInfo.originalLibraryRithmId ||
        questionInfo.originalStationRithmId
      ) {
        questionRithmIds.push(questionInfo.rithmId);
      } else {
        //If it is a custom new custom id then we create a setup by default.
        if (questionInfo.questionType === QuestionFieldType.CustomId) {
          const customSettings = this.inputFrameHelper.defaultCustomID(
            questionInfo.rithmId,
          );
          customSettings.suffixType = 'alphanumeric';
          customSettings.suffixLength = 4;
          questionInfo.settings = JSON.stringify(customSettings);
        }
        //This is going to be a new field from the current station IN the bucket.
        //So we add the current station id as the original station id.
        questionInfo.originalStationRithmId = this.stationRithmId;
        questionsBucket.push(questionInfo);
      }

      this.stationService
        .updateStationBucketQuestions(
          this.stationRithmId,
          questionRithmIds,
          questionsBucket,
        )
        .pipe(first())
        .subscribe({
          next: () => {
            this.updatingBucketLoading = false;

            this.componentTree.getStationComponents();
            this.draggedStarted = false;

            event.previousContainer.id === 'input-station-bucket'
              ? (this.componentTreeComponents.disableQuestion = '')
              : (this.libraryTree.disableQuestionRithmId = undefined);

            this.popupService.notify('Question added successfully.');
          },
          error: () => {
            this.updatingBucketLoading = false;
            this.draggedStarted = false;

            event.previousContainer.id === 'input-station-bucket'
              ? (this.componentTreeComponents.disableQuestion = '')
              : (this.libraryTree.disableQuestionRithmId = undefined);

            this.popupService.notify(
              `Couldn't update the ${TermsGeneric.Station.Single.toLowerCase()}`,
              true,
            );
          },
        });
    }
  }

  /**
   * Get's list of station bucket questions.
   */
  getStationBucketQuestions(): void {
    this.loadingIndicator = true;
    this.stationService
      .getStationBucketQuestions(this.stationRithmId)
      .pipe(first())
      .subscribe({
        next: (bucketQuestions) => {
          this.allBucketQuestions = _.cloneDeep(bucketQuestions);
          bucketQuestions = bucketQuestions.filter(
            ({ prompt, questionType, originType }) =>
              prompt !== 'Address Line 1' &&
              prompt !== 'Address Line 2' &&
              questionType !== QuestionFieldType.City &&
              questionType !== QuestionFieldType.State &&
              questionType !== QuestionFieldType.Zip &&
              // Validation per no show questions buckets the type containerName.
              questionType !== QuestionFieldType.ContainerName &&
              /** Previous fields should not be displayed in the Question bucket list. */
              originType !== OriginTypeBucket.Previous,
          );
          bucketQuestions =
            this.removeDuplicateBucketQuestions(bucketQuestions);
          this.stationBucketComponents = bucketQuestions;
          this.checkBucketQuestions();
          this.stationBucketComponentsCopy =
            this.stationService.stationBucketQuestionsCopy(bucketQuestions);
          this.loadingIndicator = false;
        },
        error: (error: unknown) => {
          this.loadingIndicator = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Remove questions duplicates.
   * @param bucketQuestions Bucket questions to filter.
   * @returns StationBucketQuestion without duplicates elements.
   */
  private removeDuplicateBucketQuestions(
    bucketQuestions: StationBucketQuestion[],
  ): StationBucketQuestion[] {
    const keys: { [key: string]: boolean } = {};
    return bucketQuestions.filter((question) => {
      return keys[question.rithmId] ? false : (keys[question.rithmId] = true);
    });
  }

  /**
   * Enable library search option once data is received.
   * @returns True once data has been received else false.
   */
  get disableLibrarySearchText(): boolean {
    return (
      !this.libraryTreeDataReceived ||
      !this.componentTreeDataReceived ||
      this.loadingGetLibraries
    );
  }

  /**
   * Say to library tab recheck its questions.
   */
  checkLibraryTabQuestions(): void {
    this.libraryTree?.checkLibraryQuestions();
    this.componentTree?.checkContainerInfoQuestions();
  }

  /**
   * Check bucket station questions.
   */
  async checkBucketQuestions(): Promise<void> {
    this.frameQuestions = this.inputFrameWidgetItems.length
      ? await InputFrame.extractFrameQuestions(this.inputFrameWidgetItems)
      : [];
    this.stationBucketComponents.forEach((libQuestion) => {
      /** Enable or disable the question in the question tree. */
      if (this.frameQuestions.length) {
        libQuestion.enabled = !this.frameQuestions.find(
          (q) => q.rithmId === libQuestion.rithmId,
        );
      } else {
        libQuestion.enabled = true;
      }
    });
  }

  /** Hide the alert of station empty. */
  hideAlertStationEmpty(): void {
    if (
      !this.inputFrameWidgetItems.length &&
      this.showAlertStationEmpty &&
      this.editMode
    ) {
      this.showAlertStationEmpty = false;
    }
  }

  /**
   * Filter stationBucketComponent with previous questions.
   * @param previousQuestions Previous questions from previous-field-tree-component.
   */
  filterBucketQuestions(previousQuestions: Question[]): void {
    this.stationBucketComponents = this.stationBucketComponents.filter(
      (stationBucket) =>
        !previousQuestions.some(
          (question) => question.rithmId === stationBucket.rithmId,
        ),
    );
  }

  /**
   * Clear search libraries and bucket.
   */
  clearSearch(): void {
    this.searchText = '';
    this.stationBucketSearchText = '';
  }

  /**
   * Should reload bucket questions and station widgets.
   */
  reloadStationWidgetsBucketQuestions(): void {
    this.getStationWidgets();
    this.componentTree.getStationComponents();
    this.componentTree.possibleBucketQuestions = [];
    this.libraryTree?.getAvailableLibraries &&
      this.libraryTree?.getAvailableLibraries();
    this.getWidgetLibraries();
  }

  /** Restore station bucket data when cancel changes in grid. */
  restoreBucketData(): void {
    this.componentTree.restoreBucketData();
  }

  /**
   * Filters the children of a library of type AddressLine from the given array of libraries.
   *
   * @param libraries The array of libraries to filter.
   * @returns The filtered array of libraries.
   */
  private filterChildrenOfLibraryOfTypeAddressLine(
    libraries: Library[],
  ): Library[] {
    const librariesFiltered = libraries.map((library: Library) => {
      const questionsFiltered = WidgetFiltersHelper.filterChildrenOfWidgets(
        library.questions,
      );
      library.questions = questionsFiltered;
      return library;
    });
    return librariesFiltered;
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
